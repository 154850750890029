export default class OpenStreetMap {

    #component = null;
    #datas = null;
    #fieldNameCoord = "coords"

    markers = [];
    map = null;
    myIcon = null;
    markerClusters = [];

    constructor(htmlComponent = null,) {
        this.setComponent(htmlComponent);
        this.startload();
    }

    /*#region Getters */
    getComponent() {
        return jQuery(this.#component);
    }

    getComponentId() {
        return this.getComponent().attr("id");
    }
    /*#endregion*/

    /*#region Setters */
    setDatas(datas = null) {
        if (datas != null) {
            
            if (typeof datas === "string") {
                try {
                    this.#datas = JSON.parse(datas);
                    console.log(this.#datas);
                } catch (e) {
                    this.closeMap();
                    throw e;
                }
            }

        }
    }

    setComponent(htmlComponent = null) {
        if (htmlComponent != null) {
            const components = Array.from(htmlComponent);
            if (components.length === 1) {
                if (components[0].id != undefined && components[0].id != "") {
                    this.#component = components[0];
                } else {
                    this.closeMap();
                    throw "The component must be have an ID";
                }

            }
            else {
                this.closeMap();
                throw "Bad definition of html component";
            }
        }
    }
    /*#endregion*/

    initDatas(datas = null, fieldNameCoord = "coords") {
      
        this.setDatas(datas);
        this.#fieldNameCoord = fieldNameCoord;
    }

    templateWindowMap() {
        return "";
    }

    defineMap(height = "500px", wheelZoom = false, view = [48.852969, 2.349903], zoom = 5) {

        this.closeMap();
        this.getComponent().css({ "height": height });

        this.map = new L.map(
            this.getComponentId().replace(/#/g, ""),
            { scrollWheelZoom: wheelZoom }
        ).setView(
            view,
            zoom
        );

        this.markerClusters = L.markerClusterGroup();
    }

    defineIconsGeoLocs(iconUrl, iconSize, iconAnchor, popupAnchor) {

        this.myIcon = L.icon(
            {
                iconUrl: this.#datas.directory_image + "/" + iconUrl,
                iconSize: iconSize,
                iconAnchor: iconAnchor,
                popupAnchor: popupAnchor,
            }
        );
    }

    generateTileMap(
        src = 'https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png',
        attribution = 'données © <a href="//osm.org/copyright">OpenStreetMap</a>/ODbL - rendu <a href="//openstreetmap.fr">OSM France</a>',
        minZoom = 1,
        maxZoom = 15) {

        L.tileLayer(src,
            {
                attribution: attribution,
                minZoom: minZoom,
                maxZoom: maxZoom
            }
        ).addTo(this.map);
    }

    definePopUpTemplate(nFunctionTemplate = function (data) { return ""; }) {
       
        this.templateWindowMap = nFunctionTemplate;
        
    }

    generateMarkers() {

        if (this.map != undefined && this.map != null && this.map != "") {
       
            this.#datas.list.forEach(obj => {
               
                const coordsJson = obj[this.#fieldNameCoord];

                if (typeof coordsJson === "string") {

                    try {

                        const coords = JSON.parse(coordsJson);
                   
                        let lat = "";
                        let long = "";

                        if (
                            coords.features[0].geometry.coordinates[1] != undefined
                            && coords.features[0].geometry.coordinates[1] != null
                            && coords.features[0].geometry.coordinates[1] != ""
                            && coords.features[0].geometry.coordinates[0] != undefined
                            && coords.features[0].geometry.coordinates[0] != null
                            && coords.features[0].geometry.coordinates[0] != ""
                        ) {
                            lat = coords.features[0].geometry.coordinates[1];
                            long = coords.features[0].geometry.coordinates[0];
                        }

                        if (lat != "" && long != "") {

                            const marker = L.marker([lat, long], { icon: this.myIcon }).addTo(this.map);
                            marker.bindPopup(
                                this.templateWindowMap(obj)
                            );

                            this.markerClusters.addLayer(marker);
                            this.markers.push(marker);
                        }
                    } catch (e) {
                        //console.log(e);
                        this.closeMap();
                        throw "Coords invalids";
                    }
                }

            });
        }

        if (this.markers.length > 0) {
            let group = new L.featureGroup(this.markers);
            this.map.fitBounds(group.getBounds().pad(0.5));
            this.map.addLayer(this.markerClusters);
            this.generate_skyp_link();
        }

    }

    startload() {
        this.getComponent().css("display", "flex");
        this.getComponent().append(
            `
            <div class="spinner-border text-muted m-auto">
                <p class="sr-only">Chargement...</p>
            </div>
            `
        );
    }

    closeMap() {
        this.getComponent().empty();
    }

    generate_skyp_link() {

        const ids = jQuery("body *");

        let listIds = [];

        for (let i = 0; i < ids.length; i++) {
            const id = jQuery(ids[i]).attr("id");
            if (id != undefined && id != "") {
                listIds.push(id);
            }
        }

        const curId = this.getComponentId().replace("#", "");

        let anchor = "";

        listIds.forEach((id, i) => {
            if (id == curId) {
                anchor = listIds[i + 1];
            }
        });

        if (anchor == "") {
            anchor = listIds[0];
        }

        this.getComponent().prepend(this.add_skip_link(anchor));
    }

    add_skip_link(anchor) {

        return `
            <a class="skip-link sr-only btn btn-negatif-white-light p-2" href="#${anchor}">Eviter la carte intéractive</a>
        `;
    }

}
