import OpenStreetMap from "./jAko/OpenStreetMap";

try {

    const openStreetMap = new OpenStreetMap(jQuery(".component-map-structures"));
    
    openStreetMap.initDatas(
        data_map_structures,
        "coord"
    );
    


    openStreetMap.defineMap(
        "300px",
        false,
        [48.852969, 2.349903],
        5
    );

    openStreetMap.defineIconsGeoLocs(
        "icon-map-cfa.png",
        [30, 40],
        [20, 30],
        [0, -30]
    );
    // console.log(data);
    openStreetMap.definePopUpTemplate(
     
        function (data) {
            let htmlPopUp = "";

            if (data != undefined && data != null && data != "") {
// console.log(data);
                htmlPopUp = `
                <div class="infowindow-structure text-center">
                    <h3 class="h5 txt-violet-d title-circle-center-gray-l bg-transparent z-index-10">${data.post_title}</h3>
                    <p class="mt-0">${data.numero_et_rue}, ${data.code_postal} ${data.ville}</p>
                    <a href="${(data.post_type != undefined && data.post_type != null && data.post_type != '') ? '/' + data.post_type : ''}${(data.post_name != undefined && data.post_name != null && data.post_name != '') ? '/' + data.post_name : ''}" class="btn btn3-bleu-cfa-f btn-radius btn-select-card" >Rechercher la fiche</a>
                </div>
            `;
            }
      
            return htmlPopUp;
        }
    );
    openStreetMap.generateTileMap();
    openStreetMap.generateMarkers();

} catch (e) {
    // console.log(e);
    
}




